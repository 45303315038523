import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Latest from './Latest';
import { format, parseISO } from 'date-fns';

const BusinessWireDetail = () =>{
const {newsId} = useParams()
const [paramValue, setParamValue] = useState(null)
const [collectionData, setCollectionData] = useState([])
const [tgData, setTagsData] = useState([])
const [login, setLogin] = useState(true)
const [latData, setLatestData] = useState({})
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/news/list/?language=hindi`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/english-wire-detail/'+item.slug,
           slugType: tgSt && tgSt.length > 0 ? true : false,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
}
const copyToText = async () => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = collectionData.copy_content;

  // Remove most HTML tags except for <p>
  let copy = '';

  // Replace specific <p> tags with double line breaks
  //copy = copy.replace(/<p><\/p>/g, '\n\n');

  // Keep specific <p> tags at the bottom
  const specificTags = tempDiv.querySelectorAll('p');
  specificTags.forEach((tag) => {
     copy += '\n\n' + tag.innerHTML;
  });

  // Combine title, short_desc, and modified content
  let final_copy = `${collectionData.title}\n\n${collectionData.short_desc} ${copy}`;
  final_copy=final_copy.replace("<br>","\n")
  final_copy=final_copy.replace('<br>','\n')
  final_copy = final_copy.replace(/&nbsp;/g, ' ')

  final_copy=final_copy.replace('<br data-mce-bogus="1">','')
  // Copy the final content to the clipboard
  await navigator.clipboard.writeText(final_copy);
 };
 const downloadToText = async () => {
   const tempDiv = document.createElement('div');
   tempDiv.innerHTML = collectionData.copy_content;
 
   // Remove most HTML tags except for <p>
   let copy = '';
 
   // Replace specific <p> tags with double line breaks
   //copy = copy.replace(/<p><\/p>/g, '\n\n');
 
   // Keep specific <p> tags at the bottom
   const specificTags = tempDiv.querySelectorAll('p');
   specificTags.forEach((tag) => {
      copy += '\n\n' + tag.innerHTML;
   });
 
   // Combine title, short_desc, and modified content
   let final_copy = `${collectionData.title}\n\n${collectionData.short_desc} ${copy}`;
   final_copy=final_copy.replace('<br>','\n')
   final_copy = final_copy.replace(/&nbsp;/g, ' ')
   final_copy=final_copy.replace('<br data-mce-bogus="1">','')
   // Copy the final content to the clipboard
   await navigator.clipboard.writeText(final_copy);
   const filename = collectionData.id+".txt"; // Replace with the desired file name
 
    const blob = new Blob([final_copy], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
  
    document.body.appendChild(a);
    a.click();
  
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
 // Usage:
 //const contentToDownload = final_copy // Replace with the content you want to download
 //const filename = "downloaded_file.txt"; // Replace with the desired file name
 
 //downloadAsTextFile(contentToDownload, filename);
 useEffect(() => {
   if(localStorage && localStorage.accessToken){
      setLogin(false)
   }
   setParamValue(newsId)
   LatestData();
   const getData = async() =>{

      const apiD =`
      https://cms.iansnews.in/api/bwi/${newsId}/?language=english
      `
      const config = {
         headers: {
         //   Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
         },
       };
      axios.get(apiD, config)
  .then(res =>
     {

      let main_content_string = res.data.results[0].content;
      let iansIndex = main_content_string.indexOf("--IANS") ? main_content_string.indexOf("--IANS") : main_content_string;
      let new_main_content_string = main_content_string.substring(0, iansIndex);
    
    
      setCollectionData({
      byline: res.data.results[0].byline,
      short_desc:res.data.results[0].short_desc,
      content: '<p>'+res.data.results[0].short_desc +'</p>'+ res.data.results[0].content,
      copy_content: res.data.results[0].content,
      id: res.data.results[0].id,
      image: res.data.results[0].thumbnail,
      title: res.data.results[0].title,
      images_thumbnails: res.data.results[0].images_thumbnails,
      //tags: res.data.results[0].tags,
      video_preview:res.data.results[0] ? res.data.results[0] :'',
      dateTime: res.data.results[0].updated_at ? formatDate(res.data.results[0].updated_at): '',
      slug:'/'+res.data.results[0].slug,
      image_caption: res.data.results[0].image_caption
    })
    const tagData = res
    && res.data 
    && res.data.results 
    && res.data.results.length > 0 
    && res.data.results[0] 
    && res.data.results[0].tags 
    && res.data.results[0].tags.length > 0 
    && res.data.results[0].tags.map((taggg,tt) => {
     return {
       id: taggg.id,
       name: taggg.name,
       slug: taggg.slug
     }
  })
      console.log("Your new array of modified objects here",collectionData)
  // setName((data)=>{
  //
  //   // return [...data, cateData];
  // });
  setTagsData(tagData);
})
.catch(err => { console.log('Google api calendar error', err) })
}
getData();
}, [newsId])
const printNews =()=>{
  window.print();
}
const shareinsocialmedia=(url)=>{
  window.open(url,'sharein','toolbar=0,status=0,width=648,height=395');
	return true;
}
    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header />
         <main id="main">
            <section className="inner-page inner-latest-news">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-9 col-md-6 d-flex align-items-stretch order-d-1" id="content1">
                        <div className="icon-box1">
                           <div id="print">
                              <div className="inews">
                                 <h2 className="title" id="newsheading">{collectionData.title}</h2> 
                                
                              </div>						 
                              <div className="ians-userdetail-share ians-story-author-section"> 
                                 <div className="ians-author-detial-left"> 
                                    <div className="ians-author-brand-detial">   
                                       <h4>{collectionData.byline}</h4>
                                       <ul> 
                                          <li>{collectionData.dateTime}</li> 
                                       </ul>
                                    </div>
                                 </div>
       
                              </div>					 
                              <div className="full-article">

                                
                                   <div className="full-article-details" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                              </div>
                              <ul className="list-img-col">
                                 {collectionData && collectionData.images_thumbnails && collectionData.images_thumbnails.length > 0 && collectionData.images_thumbnails.map((rel, r)=>{
                                    return(
                                       <li key={r}><figure><span className="img-col-bottom"><a href={'https://photo.iansnews.in/detail/'+rel.slug} target="_blank"><img alt="#"  src={rel.image ? rel.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg'}/></a></span></figure></li>
                                    )
                                 })}									
                              </ul>
                           </div>
                           <blockquote>
                           स्पष्टीकरण/प्रश्नों के लिए, कृपया आईएएनएस न्यूज डेस्क से संपर्क करें:
                              <span className="quoter">
                                 <ul>
                                    <li><i aria-hidden="true" className="fa fa-phone"></i> +91-120-4822400</li>
                                    <li><i aria-hidden="true" className="fa fa-mobile"></i> +91-9311830312</li>
                                    <li><i aria-hidden="true" className="fa fa-envelope"></i> support@ians.in</li>
                                 </ul>
                              </span>
                           </blockquote>
                           {/* <RealtedNews /> */}
                        </div>
                     </div>        
                     <Latest data={latData}/>
                  </div>	
               </div>	
            </section>
         </main>
         <Footer/>       
        </>
    )
}

export default BusinessWireDetail