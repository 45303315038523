import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios'
import {NavLink} from 'react-router-dom'
import copy from "copy-to-clipboard"

import FileSaver from "file-saver";
import {Helmet} from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { format, parseISO } from 'date-fns';
import Permision from './Permision';

const PhotoWireDetail = () =>{
const history = useHistory();
const [backImage, setBackImage] = useState('')
const {photoId} = useParams();
const [isLoading, setIsLoading] = useState(false);
const [photoCollectionData, setPhotophotoCollectionData] = useState('[]');
const [tgData, setTagsData] = useState([]);
const [login, setLogin] = useState(true)
const [errorMsg, setErrorMsg] = useState('');
const [eventData, setEventsData] = useState();


const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
}
const [collectionData, setCollectionData] = useState({})

const getEventData =(newsId)=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/imagenews/detail/${newsId}/?language=english`
   )
   .then((res) => {
       setCollectionData({
           dateTime: res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
           title : res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].title ? res.data.results[0].title: '',
           tags : res.data.results &&
           res.data.results.length > 0 && 
           res.data.results[0].tags ? res.data.results[0].tags: ''
       });
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results[0].images_thumbnails.length > 0 &&
       res.data.results[0].images_thumbnails.map((item) => {

       return {
           content: item.caption,
           id: item.id,
           image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
           title: item.title,
           slug:'/photo-wire-detail/'+item.slug
       }});
       setEventsData(latestStories)
   }).catch(err => {
      console.log('Google api calendar error', err) 
      if(err && err.response && err.response.data && err.response.data.detail
      ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
         setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
         //history.push('/permision');
      }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
         setErrorMsg(err.response.data.detail)
         //history.push('/permision');
      }
      }})
}
const imageDownload =(url)=>{
   // if(eventL && eventL== 1){
   //    window.removeEventListener();
   //    setEventL(eventL+1)
   // }
//    console.log('vikkk',caches)
//    caches.keys().then((names) => {
//       names.forEach((name) => {
//           caches.delete(name);
//       });
//   });
   setTimeout(image2Download(url), 50000)
   

}
const image2Download=(url)=>{
   setIsLoading(true);

  //alert("Complete Cache Cleared");
            let modifiedUrl = url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          let timestamp = new Date().getTime();
          fetch(`${updateUrl}?t=${timestamp}`)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
          handleSubmit(photoCollectionData.title, photoCollectionData.id)
         
  }

  const getData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/image/detail/${photoId}/?language=english`
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   let new_main_content_string = '';

    
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.results[0] && res.data.results[0].byline){
           let newIi = res.data.results[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
     
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }

   }
  
   setPhotophotoCollectionData({
      caption:res.data.results[0].caption,
      content: '<p>'+res.data.results[0].short_desc +'</p>'+ new_main_content_string,
      copy_content: res.data.results[0].caption,
      id: res.data.results[0].id,
      image: res.data.results[0].thumbnail,
      title: res.data.results[0].title,
      images_thumbnails: res.data.results[0].images_thumbnails,
      url: res.data.results[0].url,
      dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
      slug:'/'+res.data.results[0].slug,
      image_caption: res.data.results[0].image_caption
 })
 setBackImage(`background: url(${res.data.results[0].thumbnail}) no-repeat 50% 50%`)

 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.results[0] 
 && res.data.results[0].tags 
 && res.data.results[0].tags.length > 0 
 && res.data.results[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",photoCollectionData)
setTagsData(tagData);
})
.catch(err => {
   console.log('Google api calendar error', err) 
   if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
      //history.push('/permision');
   }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
      setErrorMsg(err.response.data.detail)
      //history.push('/permision');
   }
   }})
}
const handleSubmit = (title, id) => {
   //e.preventDefault();
   let formData = {
      "content_id":  id ? id : '',
      "username": localStorage && localStorage.userName ? localStorage.userName : '',
      "content_type": "Photo",
      "content_title": title ? title : ''
   }
   axios.post('https://cms.iansnews.in/api/download_records/', formData)
     .then(response => {
       console.log('Success:', response.data);
       // Optionally, reset form fields

     })
     .catch(error => {
       console.error('Error:', error);
     });
 };
 const[eventL, setEventL] = useState(1)
 const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const toggleVisibility = () => {
   if (window.pageYOffset > 500) {
       setIsVisible(true)
   } else {
       setIsVisible(false)
   }
}
const copyToText = async () => {
   const tempDiv = document.createElement('div')
   tempDiv.innerHTML = photoCollectionData.copy_content

   // Remove most HTML tags except for <p>
   let copyt = ''

   // Replace specific <p> tags with double line breaks
   //copy = copy.replace(/<p><\/p>/g, '\n\n');

   // Keep specific <p> tags at the bottom
   const specificTags = tempDiv.querySelectorAll('p')
   specificTags.forEach((tag) => {
      copyt += '\n\n' + tag.innerHTML;
   });

   // Combine title, short_desc, and modified content
   let final_copy = '';
   //if(final_copy){
      final_copy = `Title : ${photoCollectionData.title}\n\n Caption : ${photoCollectionData.caption} ${copyt}`
      final_copy=final_copy.replace("<br>","\n")
      final_copy=final_copy.replace('<br>','\n')
      final_copy = final_copy.replace(/&nbsp;/g, ' ')
      final_copy=final_copy.replace('<br data-mce-bogus="1">','')
      copy(final_copy);
      // handleSubmit(collectionData.title, collectionData.id)
   //}
   // Copy the final content to the clipboard
   //await navigator.clipboard.writeText(final_copy);
   }

 useEffect(() => {
   if(!localStorage || localStorage.length == 0 || !localStorage.accessToken){
      localStorage.setItem('redirectTo', photoId ? '/'+photoId :'');
      localStorage.setItem('langee', 'photo');
      history.push('/login');
  }else if(localStorage && localStorage.length > 0 && localStorage.accessToken && (!localStorage.photoStatus || localStorage.photoStatus=='false' || localStorage.photoStatus == false)){
     // history.push('/permision');
   }   
   if(localStorage && localStorage.accessToken){
      setLogin(false)
   }else{
      setLogin(true)
   }
   let tempId = [];
   if(photoId){
tempId = photoId.split("-id-")
if(tempId && tempId.length > 0){
   getEventData(tempId[0])
}
}
   getData()
   scrollToTop()

   window.addEventListener("scroll", toggleVisibility)
   return () => window.removeEventListener("scroll", toggleVisibility)
}, [photoId])

    return(
        <>
         <Helmet>
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <title>Indo-Asian News Service-{photoCollectionData.title ? photoCollectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={photoCollectionData.keywords ? photoCollectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content={photoCollectionData.short_desc} />
            <meta property="og:title" content={photoCollectionData.title} />
            <meta property="og:description" content={photoCollectionData.short_desc} />
            <meta property="og:image" content={photoCollectionData.image} />
            <meta property="og:url" content={'https://iansphoto.in/detail'+photoCollectionData.slug} />
            <meta property="og:type" content="article" />
        </Helmet>
        
        {!errorMsg    &&
         <Header show={login}/>}      
{  !errorMsg    &&
         <main id="main">
            <div className="container full-news full-pic-sec">
               <section id="about" className="about">
                     <div className="row">
                        <div className="col-lg-7">
                        <div id="print">                           
                           <div className="full-article">
                              <figure>
                              
                                 <a href={photoCollectionData.img_slug} title={photoCollectionData.title}>
                                    <img src={photoCollectionData.image} alt={photoCollectionData.title} />
                                 </a>
                                 <style>
                                    {`
                                       .full-article figure a:after {
                                          ${backImage};
                                       }
                                    `}
                                 </style>                            
                              </figure>
                           </div>
                        </div>
                        </div>
                        <div className="col-lg-5 latest-news-sec">
                           <div className="inews">
                              <h2 className="title" id="newsheading">{photoCollectionData.title}</h2>
                           </div>
                           <p><strong>Photo ID:</strong> {photoCollectionData.id}</p>
                           <p><strong>Caption:</strong> {photoCollectionData.caption}</p>
                           <p><strong>Release Date & Time: </strong>{photoCollectionData.dateTime}</p>
                           <p><strong>Tags: </strong> {tgData && tgData.length > 0 && tgData.map((tgg, t)=>{
                                    return(
                                       <>
                                       <span key={t}> {tgg.name} </span>
                                       
                                       </>
                                    )
                                 })},</p>
                           <div className="downloadhbtn">
                              <NavLink onClick={()=>{imageDownload(photoCollectionData.url)
                                 }}alt="Download file" to={`/photo-wire-detail/${photoId}`} title="Download Picture" >
                                 <span>Download Picture</span>
                                 <i alt="Download file" className="bi bi-download"></i>
                              </NavLink>
                              <NavLink to="#" title="Copy Text" onClick={copyToText}>
                                 <span>Copy Text</span>
                                 <i alt="Copy file" className="copyicon"></i>
                              </NavLink>
                           </div>                         
                        </div>
                     { eventData && eventData.length > 0 &&
                      <div className="col-lg-12">
                            <div className="row event-photo">
                                <div className="video-tab-sec">
                                    <h2 className="title">{"Related Images"}</h2>
                                </div>
                                
                               
                            { eventData.map((imgArrry, i)=>{
                                    return(
                                    <div className="col-xl-3 col-lg-4 col-md-6 mb-3 sec-left">
                                        <NavLink to={imgArrry.slug} title={imgArrry.title}>
                                        <div className="thumb w-100  album-thumb">
                                            <img className="thumb-image w-100" src={imgArrry.image}/>
                                            <div className="text-l-gray des-title mt-3"> {imgArrry.title}</div>
                                            
                                        </div>
                                        </NavLink>
                                    </div>
                            )})} 
                            </div>
                        </div>
                        }
                     </div>
               </section>
            </div>
         </main>  
         }      
         {errorMsg &&
         <Permision />
         }
         {!errorMsg && <Footer/>}          
        </>
    )
}

export default PhotoWireDetail