import React,{useState, useEffect,useRef, useCallback} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import HomeSlider from './HomeSlider';
import HeadingTab from './HeadingTab';


const PhotoWireSearch = ()=>{
const [cateData, setCateData] = useState({})
const [searchHead, SetSHead] = useState('template-search');

const [engData, setEngData] = useState()
const categoryList = [
    {
        name:'National',
        slug:'national'
    },
    {
        name:'International',
        slug:'international'
    },
    {
        name:'Business',
        slug:'business'
    },
    {
        name:'Entertainment',
        slug:'entertainment'
    },
    {
        name:'Science/Tech',
        slug:'science-tech'
    },
    {
        name:'Sports',
        slug:'sports'
    },
    {
        name:'Health/Medicine',
        slug:'health-medicine'
    }    
]
const [cateText, setCategoryName] = useState('all')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const [resultCount, setResultCount] = useState(0)

const {search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)

const [latData, setLatestData] = useState()

const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/imagenews/list/?language=english&page=1`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 6).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           id: item.id,
           image: item.image,
           title: item.title,
           slug:'/photo-event/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at): ''
       }})
   setLatestData(latestStories)
   })
}
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
const getCatgoryData=(obj)=>{
    if(obj != cateText){
        setCategoryName(obj)
        setCurrentPage(1)
        history.push('/english-wire/1');
    }
    //fetchData()
}
const searchData=(data)=>{
    setSearchText(data)
    history.push('/photo-search/'+data+'/1');

}
const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/photo-search/'+search+'/'+startPg
    
    history.push(pathh)
    
}
const fetchData = async (page) => {
  
    page = page ? page: 1;
    let getApii = `https://cms.iansnews.in/api/elastic/imagenews/search/?language=english`;
    if(search){
        //cateText = `&tags=${cateText}`;
        getApii += `&query=${search}`;
    }
    // if(inputSearch){
    //     getApii += `&query=${inputSearch}`;
    // }
    getApii += `&page=${page}`;

    axios.get(getApii)
    .then(res => {
    if(lastPage == 1){
        setLastPage(res.data.numpages);
    }
    const getStories =
    res.data.results &&
    res.data.results.length > 0 &&
    res.data.results.map((item) => {
    let tgSt= item.tags.filter(it => it.name === 'Business')
    return {
        byline: item.byline,
        content: item.short_desc,
        id: item.id,
        image: item.thumbnail,
        image_count:item.image_count,
        dateTime: item.created_at ? formatDate(item.created_at): '',
        title: item.title,
        tags: item.tags,
        slug:'/photo-event/'+item.slug,
        short_desc:item.short_desc,
        // slugType: tgSt && tgSt.length > 0 ? true : false,
        video_preview:item.video_url? item.video_url :'',
        image_caption: item.image_caption
    }})
    setEngData(getStories);
    setLt(res.data.numpages);
    setRequestFlag(false);
    if(res && res.data && res.data.total_hits){
        setResultCount(res.data.total_hits)
    }
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    })
}

const handleNextPage = () => {
    if(lastPage > currentPage){
    setCurrentPage(currentPage + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
const onSubmit = (e) => {
    e.preventDefault()

      let path = '/photo-search/'+inputSearch+'/1';
        //SetSHead('template-search')
        //window.reload()
       // window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
useEffect(() => {
LatestData()
//if(currentPage == offset){
    fetchData(currentPage)
    setStartPage(currentPage)

//}
scrollToTop()
//setCurrentPage(offset)
window.addEventListener("scroll", toggleVisibility)
return () => window.removeEventListener("scroll", toggleVisibility)
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, search, offset])
return(
<>
    <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
    <meta data-meta-title="IANS IN" name="keywords" content={''} />
    <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
    </Helmet>
    <Header />
    <HomeSlider/>
    <main id="main">
        <section id="about" className="about">
            <div className="container">
                <HeadingTab />
                <div className="row">
                        <div className="col-lg-9 tab-content left-padding" id="myTabContent">
                        <ul className="top-category-list-bottom tab-pane fade show active" id="english" role="tabpanel" aria-labelledby="home-tab">
                            <div className="main-heading">
                                <h2 className="heading">Search Results : {`${search}`}</h2>

                            </div>

                        { engData && engData.length > 0 && engData.map((PhotoTemp ,et)=>{
                            return(
                                <>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-3 sec-left" key={et}>
                                <NavLink title={PhotoTemp.title} to={PhotoTemp.slug}>
                                    <div className="thumb w-100  album-thumb p-4">
                                        <img className="thumb-image w-100" src={PhotoTemp.image}/>
                                        <div className="photo-count">
                                            <div className="content">
                                                <div className="number">{PhotoTemp.image_count}</div>
                                                <div className="label">PHOTOS</div>
                                            </div>
                                        </div>
                                        <div className="text-l-gray des-title mt-3">{PhotoTemp.title}</div>
                                        <div className="label text-white label-photo">{PhotoTemp.dateTime}</div>
                                        <div className="videotag">
                                            <div className="videotag__container">
                                            {PhotoTemp.tags && PhotoTemp.tags.length > 0 && PhotoTemp.tags.map((tgg,t)=>{
                                                return(
                                                    <NavLink className="videotag__item"  to={`/photo-wire/${tgg.slug}/1`}>{tgg.name}</NavLink>
                                            )})}

                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                                </>
                        )})}

<nav aria-label="Page navigation example">
                            <ul className="pagination">
                            { currentPage && currentPage > 1 &&                              
                                <li className="page-item" onClick={handlePrevPage}>
                            <NavLink to={`/photo-search/${search}/${currentPage-1}`} className="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                            </NavLink>
                                </li>
                            }
                                <li className="page-item"onClick={()=>{
                                    setCurrentPage(currentPage)
                                    }}>
                            <a className="page-link active" href="#">{currentPage}</a></li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(currentPage+1)
                                }}>
                                <NavLink to={`/photo-search/${search}/${parseInt(currentPage)+1}`} className="page-link">{parseInt(currentPage)+1}</NavLink>
                                </li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(currentPage+2)
                                }}>
                                <NavLink to={`/photo-search/${search}/${parseInt(currentPage)+2}`} className="page-link">{parseInt(currentPage)+2}</NavLink>
                                </li>
                                <li className="page-item" onClick={handleNextPage}>
                                <NavLink to={`/photo-search/${search}/${parseInt(currentPage, 10) + 1}`} className="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </NavLink>
                                </li>
                                <li className="go_page"> Page 
                                        <input type="number" name="quantity" min={startPg} max={lastPage}  onChange={inputGSData} value={startPg}/>
                                        <i className="go_btn" aria-hidden="true" onClick={GoToPage}>GO</i>
                                </li>                                 
                            </ul>
                        </nav>
                        </ul>
                    </div>
                    <div className="col-lg-3 latest-photo-sec right-sec">
                    <div className="search-form-allphoto">
                            <form onSubmit={onSubmit}>            
                                <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                            </form>                      
                        </div>
                        <div className="main-heading">
                                <h2 className="heading">LATEST EVENT</h2>
                        </div>
                        <ul className="list-unstyright">
                            {latData && latData.length > 0 && latData.map((tempLD, d)=>{
                                return(
                                    <li key={d}>
                                    <figure>
                                        <figcaption className="tabtextarea-unstyright">
                                            <NavLink title={tempLD.title} alt={tempLD.title} to={tempLD.slug}>
                                                <h3 className="title">{tempLD.title}</h3>
                                            </NavLink>
                                        </figcaption>
                                        <div className="img-area-unstyright">
                                            <NavLink to={tempLD.slug} title={tempLD.title}>
                                                <img src={tempLD.image} title={tempLD.title} alt={tempLD.title}/>
                                            </NavLink>
                                            <div className="img-box-wrap">
                                                <i className="bi bi-camera" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </figure>
                                </li>
                                )
                            })}
                            

                        </ul>
                </div>
                </div>
            </div>
        </section>
    </main>               
    <Footer />  
</>
)
}
export default PhotoWireSearch