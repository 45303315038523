import React from 'react'
import {NavLink} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

const Footer=()=>{
  const year = new Date().getFullYear();

    return (
        <>
<ScrollToTop />
  <footer id="footer">
    <div className="footer-top">

    <div className="col-lg-12 col-md-12 footer-partner">
          <ul className="list-unstyled">
            <li>
              <a href="/pr-news-wire/1">पीआर न्यूजवायर</a> 
            </li>
            <li>
              <a href="/business-wire/1">बिजनेस वायर इंडिया</a> 
            </li>
          </ul>
        </div>
      <div className="container">
        
        <div className="row">

          {/* <div className="col-lg-4 col-md-6 footer-info">

			<img src="https://ians.in/assets/img/w-logo.png" alt="" style={{width:'74%'}}/>
          </div> */}

          <div className="col-lg-4 col-md-6 footer-links services-link">
            <h4>सेवाएँ</h4>
            <ul>
              <li><a href="/english-wire/all">इंग्लिश न्यूज़ </a></li>
              <li><a href="/hindi-wire/all">हिंदी न्यूज़ </a></li>
              <li><a href="/photo-wire/all/1">फोटो सर्विस </a></li>
              <li><a href="/video-wire/all/1">वीडियो सर्विस</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>उपयोगी कड़ियां</h4>
            <ul>
              <li><a href="/">होम</a></li>
              <li><a href="/about-us">हमारे बारे में</a></li>
              <li><a href="/contact-us">हमसे संपर्क करें</a></li>
              <li><a href="/services">सेवाएँ</a></li>
              <li><a href="/terms-of-use">नियम और शर्तें</a></li>
              <li><a href="/privacy-policy">गोपनीयता नीति </a></li>
              <li><a href="/technical-support">तकनीकी सहायता</a></li>
              <li><a href="/career">करियर</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-contact">
            <h4>संपर्क करें</h4>
            <p>
              आईएएनएस इंडिया प्राइवेट लिमिटेड <br />
              ए-6, ग्राउंड फ्लोर, ब्लॉक ए<br/>
              सेक्टर-16, नोएडा, उत्तर प्रदेश<br />
              <strong>फ़ोन:</strong> +91-120-4822400, 4822415, 4822416<br />
              <strong>ईमेल:</strong> dakul.s@ians.in, marketing@ians.in<br />
            </p>
            {/* <div className="social-links">
              <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
              <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
              <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
            </div> */}
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">कॉपीराइट © 2024 इंडो-एशियन न्यूज सर्विस (आईएएनएस) इंडिया प्राइवेट लिमिटेड। डिजाइन और डेवलपमेंट आईएएनएस सॉफ्टवेयर सर्विसेज
      </div>
    </div>
  </footer>
  

  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </>
    )
}
export default Footer