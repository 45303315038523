import React,{useState, useEffect,useRef, useCallback} from 'react'
import axios from 'axios'
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns'
import { useHistory } from "react-router-dom"
import HomeSlider from './HomeSlider'
import Top from './Top'
import HeadingTab from './HeadingTab'
import {useStickyBox} from "react-sticky-box";
import VideoCat from './videoCat'
import Breaking from './Breaking'



const BreakingNews = ()=>{

const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
const [engData, setEngData] = useState()

const [cateText, setCategoryName] = useState('all')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {categoryId, search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const [latData, setLatestData] = useState();

const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/news/list/?language=hindi`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 6).map((item) => { 
        return {
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/hindi-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
    setLatestData(latestStories)
    })
 }
 const [topData, setTopData] = useState();

const getTopNewsData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/news/list/?top_news=true&language=hindi`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.map((item) => {
 
        return {
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/hindi-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
        setTopData(latestStories)
    })
 }
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}

const searchData=(data)=>{
    setSearchText(data)
    history.push('/hindi-wire/'+data+'/1');

}

const fetchData = async (page) => {
   // alert(categoryId)
    page = page ? page: 1;
    let getApii = `https://cms.iansnews.in/api/elastic/news/list/?language=hindi`;
    if(categoryId && categoryId !='all'){
        //cateText = `&tags=${cateText}`;
        getApii += `&tags=${categoryId}`;
    }
    if(inputSearch){
        getApii += `&query=${inputSearch}`;
    }
    getApii += `&page=${page}`;

    axios.get(getApii)
    .then(res => {
    
        setLastPage(res.data.numpages);
   
    const getStories =
    res.data.results &&
    res.data.results.length > 0 &&
    res.data.results.map((item) => {
    let tgSt= item.tags.filter(it => it.name === 'Business')
    return {
        byline: item.byline,
        content: item.short_desc,
        id: item.id,
        image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
        image_count:item.image_count,
        dateTime: item.created_at ? formatDate(item.created_at): '',
        title: item.title,
        tags: item.tags,
        slug:'/hindi-wire-detail/'+item.slug,
        short_desc:item.short_desc,
        video_preview:item.video_url? item.video_url :'',
        image_caption: item.image_caption
    }})
    setEngData(getStories);
    setLt(res.data.numpages);
    setRequestFlag(false);
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    })
}

const handleNextPage = () => {
    if(lastPage > currentPage){
    setCurrentPage(parseInt(currentPage) + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
const onSubmit = (e) => {
    e.preventDefault()
    
    let path = ''
    if(inputSearch && inputSearch !='undefined'){
        path = '/english-search/'+inputSearch+'/1'
    }else{
        path = '/english-wire/all/1'
    }
        //SetSHead('template-search')
        //window.reload()
       // window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
    

}
const [breaking, setBreakData] = useState([])

const getBreakData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/breakingnews/list/?is_active=true`
    )
    .then((res) => {
    const breakk =
        res.data &&
        res.data.length > 0 &&
        res.data.map((item) => {
        return {
            created_at:item.created_at,
            headline: item.headline,
            is_active : item.is_active,
            updated_at: item.updated_at
        };
        });
        setBreakData(breakk);
    });
}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
useEffect(() => {
    if(categoryId){
        setCategoryName(categoryId)
    }
    if(!localStorage || !localStorage.accessToken || !localStorage.engStatus){
        history.push('/login');
    }
    
    setCurrentPage(offset)
    getTopNewsData()
    getBreakData()
    LatestData()
    fetchData(currentPage)
    scrollToTop()

    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)

//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, search])
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header />

    <HomeSlider/>
    <main id="main">      
        <div className="container">
            <section id="about" className="about">
                <HeadingTab />
                <div className="row">
                    <div className="col-lg-8 breaking-news-col">
                        <div className="breaking-news">
                            <div className="rhs-head">
                                <span>Breaking</span> News
                            </div>
                            <ul>
                                <li>
                                    <div>
                                        <h3>'Repeating baseless arguments': India responds to China on Arunachal</h3>
                                        <p>The Ministry of External Affairs (MEA) on Tuesday lashed out at China for "advancing absurd claims" over the territory of Arunachal Pradesh and reiterated that the northeastern state will always remain its "integral and inalienable part" of India. The response from the Ministry came after Beijing said that it "never acknowledges and firmly opposes" the "so-called Arunachal Pradesh illegally established by India."</p>
                                        <span className="update-info">10 mins ago</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3>PM Modi's Speech Live | PM Modi's public rally in Salem, Tamil Nadu | Lok Sabha Election 2024</h3>
                                        <div className='video-i'>
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/_2-eVCY4xwc?si=HZk8QAYz5b2O0aG_?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3>Ahmedabad-Mumbai bullet train to be launched in 2026: Railway Minister Ashwini Vaishnaw</h3>
                                        <p>Railway Minister Ashwini Vaishnaw said on Tuesday that the Ahmedabad- Mumbai bullet train will be launched in 2026. “India's first bullet train will be ready in 2026, and will run in one section from Surat,” the minister said.</p>
                                        <span className="update-info">10 mins ago</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3>Thousands line streets as PM Modi holds roadshow in Kerala's Palakkad</h3>
                                        <div className='video-i'>
                                        <img src="https://iansportalimages.s3.amazonaws.com/images/202403193134048.jpg" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3>'Repeating baseless arguments': India responds to China on Arunachal</h3>
                                        <p>The Ministry of External Affairs (MEA) on Tuesday lashed out at China for "advancing absurd claims" over the territory of Arunachal Pradesh and reiterated that the northeastern state will always remain its "integral and inalienable part" of India. The response from the Ministry came after Beijing said that it "never acknowledges and firmly opposes" the "so-called Arunachal Pradesh illegally established by India."</p>
                                        <span className="update-info">10 mins ago</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3>'Repeating baseless arguments': India responds to China on Arunachal</h3>
                                        <p>The Ministry of External Affairs (MEA) on Tuesday lashed out at China for "advancing absurd claims" over the territory of Arunachal Pradesh and reiterated that the northeastern state will always remain its "integral and inalienable part" of India. The response from the Ministry came after Beijing said that it "never acknowledges and firmly opposes" the "so-called Arunachal Pradesh illegally established by India."</p>
                                        <span className="update-info">10 mins ago</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>               
    <Footer />  
</>
)
}
export default BreakingNews