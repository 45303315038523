import React,{useState, useEffect,useRef, useCallback} from 'react'
import axios from 'axios'
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns'
import { useHistory } from "react-router-dom"
import HomeSlider from './HomeSlider'
import Top from './Top'
import HeadingTab from './HeadingTab'
import {useStickyBox} from "react-sticky-box";
import VideoCat from './videoCat'
import Modal from 'react-bootstrap/Modal';
import jsonData from './Data/LS_Winner_INDIA2.json'
import ElectionHome from './ElectionHome'

const ElectionResult = ()=>{

const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
const [engData, setEngData] = useState()

const [cateText, setCategoryName] = useState('all')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {categoryId, search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const [startPg, setStartPage] = useState(1)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const [latData, setLatestData] = useState();


const [show, setShow] = useState(false)
const handleClose = () => setShow(false)

const getUniqueStates = (data) => {
    const seen = new Set();
        return data.reduce((acc, item) => {
            if (!seen.has(item.s)) {
            seen.add(item.s);
            acc.push(item.s);
            }
            return acc;
        }, []);
}   
const getUniqueParties = (data) => {
    const seen = new Set();
        return data.reduce((acc, item) => {
            if (!seen.has(item.lp)) {
            seen.add(item.lp);
            acc.push(item.lp);
            }
            return acc;
        }, []);
}  
const getUniquePartiesWithCounts = (data) => {
    const partyCounts = {};
    data.forEach((item) => {
        const partyName = item.lp;
        partyCounts[partyName] = (partyCounts[partyName] || 0) + 1;
    });
    return partyCounts;
};
const [state, setStateList] = useState()
const [party, setPartyList] = useState()
const [assemData, setAssemData] = useState()
const [conData, setConData] = useState()
const [allData, setAllData] = useState()
const [selectedValue, setSelectedValue] = useState('')
const [selectedPartyValue, setSelectedPartyValue] = useState('');
const [selectedConsValue, setSelectedConsValue] = useState('');
const [partyCountD, setPartyCountD] = useState()
const handleSelectChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value in state
    setSelectedConsValue('')
    setSelectedPartyValue('')
  }
  const handleSelectPartyChange = (event) => {
    setSelectedPartyValue(event.target.value); // Update the selected value in state
  }  
  const handleSelectConsChange = (event) => {
    setSelectedConsValue(event.target.value); // Update the selected value in state
  }  
const getStateList =(data)=>{
    if(data ){
       const uniqueStates = getUniqueStates(data)
       const sortedItems = [...uniqueStates].sort((a, b) => a.localeCompare(b));
       setStateList(sortedItems)
    }
}
const getPartyList=(data)=>{
    const uniqueParties = getUniqueParties(data)
    const sortedttItems = [...uniqueParties].sort((a, b) => a.localeCompare(b));
    setPartyList(sortedttItems)
    const partyCounts = getUniquePartiesWithCounts(data)
    setPartyCountD(partyCounts)
//console.log(partyCounts)
}   
const convertObjectToArray = (obj) => {
    return Object.values(obj)
  }
 
const clearAllFun = ()=>{
    let arrayTemp = "";
    setSelectedConsValue('')
    setSelectedValue('')
    setSelectedPartyValue('')
    if(dataTemp && dataTemp.india && dataTemp.india.cs){
        arrayTemp = convertObjectToArray(dataTemp.india.cs)
    }
     if(arrayTemp){
        setAssemData(arrayTemp)
        setConData(arrayTemp)
        getStateList(arrayTemp)
        getPartyList(arrayTemp)
        getJsonData()
        setAllData(arrayTemp)
     }
}
const getJsonData = ()=>{
    let ttarray = ""
    // const url = `https://ianshindi.in/election_2024.json`;
    // Fetch the JSON file using axios
    // axios
    //   .get(url)
    //   .then((response) => {
    //     if(response && response.data){
    //         if(response.data && response.data.india && response.data.india.cs){
    //             ttarray = convertObjectToArray(response.data.india.cs)
    //        }
    //        if(ttarray){
    //            setAssemData(ttarray)
    //            setConData(ttarray)
    //            getStateList(ttarray)
    //            getPartyList(ttarray)
    //            setAllData(ttarray)            
    //        }
    //        setData(response.data);
    //        //console.log(response.data, "ttgwalior_praveen_pathak");
    //     }
    //   })
    //   .catch((error) => {
    //     //setError(error);
    //     //console.error('Error fetching the JSON file:', error);
    //   });


    // for local

    if(jsonData && jsonData){
        if(jsonData && jsonData.india && jsonData.india.cs){
            ttarray = convertObjectToArray(jsonData.india.cs)
       }
       if(ttarray){
           setAssemData(ttarray)
           setConData(ttarray)
           getStateList(ttarray)
           getPartyList(ttarray)
           setAllData(ttarray)            
       }
       setData(jsonData);
       //console.log(response.data, "gwalior_praveen_pathak");
    }
}
const [dataTemp, setData] = useState()
let timestamp = new Date().getTime();
useEffect(() => {
let array ="";
    if (selectedValue) {
        const filteredData = allData.filter(item => item.s === selectedValue);
        // filteredData.sort((a, b) => a.s.localeCompare(b.s));
        setAssemData(filteredData)
        setConData(filteredData)
        //setSelectedConsValue('')
        
      } else {
        // If no state is selected, show all data
        if(dataTemp && dataTemp.india && dataTemp.india.cs){
             array = convertObjectToArray(dataTemp.india.cs)
        }
        if(array){
            setAssemData(array)
            setConData(array)
            getStateList(array)
            getPartyList(array)
            setAllData(array)            
        }
        if(!selectedPartyValue){
            getJsonData()
        }

      }
      console.log(jsonData,"cccccc");
      if(selectedPartyValue){
        const filteredprtData = allData.filter(item => item.lp === selectedPartyValue);

        setAssemData(filteredprtData)        
      }
      if(selectedConsValue){
        const filteredConsData = allData.filter(item => item.n === selectedConsValue);
        // filteredData.sort((a, b) => a.s.localeCompare(b.s));
        setAssemData(filteredConsData)        
      }
  

//  .catch(err => { console.log('Google api calendar error', err) })
}, [selectedValue, selectedConsValue, selectedPartyValue])
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header  showType={true}/>

 {/* <ElectionHome /> */}

    
    <main id="main">
        <div className="container poll-page">
            <section id="about" className="about ls-poll">
                
                    <div className='row'>
                        <div className="col-md-12">
                            <form>
                                <select value={selectedValue} onChange={handleSelectChange}>
                                    <option value="">Select State</option>
                                    {state && state.length > 0 && state.map((sttt, s)=>{
                                        return(
                                            <>
                                            <option key={s} value={sttt}>{sttt} </option>
                                            </>
                                        )
                                    })}
                                    
                                </select>
                {  selectedValue &&
                            <select value={selectedConsValue} onChange={handleSelectConsChange}>
                                    <option value="">Select Constituency</option>
                                    {conData && conData.length > 0 && conData.map((stt, s1)=>{
                                        return(
                                            <>
                                            <option key={s1} value={stt.n}>{stt.n} </option>
                                            </>
                                        )
                                    })}
                                    
                                </select> }  
                { !selectedValue && !selectedConsValue &&
                            <select value={selectedPartyValue} onChange={handleSelectPartyChange}>
                                    <option value="">Leading (Party wise)</option>
                                    {party && party.length > 0 && party.map((pp, sp)=>{
                                        return(
                                            <>
                                            <option key={sp} value={pp}>{pp} </option>
                                            </>
                                        )
                                    })}
                                    
                                </select> }        
                                <b onClick={clearAllFun}>Refresh all</b>                     
                            </form>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-12">
                            <table className="medal_table">
                                <tr className="india-m">
                                    <th className="imgpad">Seat</th>
                                    <th className="imgpad m-none">State</th>
                                    <th className="imgpad">Leading</th>
                                    <th className="imgpad">Party</th>
                                    <th className="imgpad m-none">Trailing</th>
                                    <th className="imgpad m-none">Party</th>
                                    {/* <th className="imgpad">Status</th> */}
                                </tr>
                                {
                                        assemData && assemData.length > 0 && assemData.map((asse, s)=>{
                                            return(
                                                <>
                                                    <tr  key={s}>
                                                        <td>{asse.n ? asse.n : ''}</td> 
                                                        <td className="m-none">{asse.s ? asse.s :''}</td> 
                                                        <td>{asse.c ? asse.c : ''}</td> 
                                                        <td className="lp-plogo">
                                                        {   asse.lp && asse.lp.length > 0 &&                  
                                                        <img src={`https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/${asse.lp.toUpperCase()}.jpg`}/>
                                                        }     {asse.lp ? asse.lp : ''}                                                    
                                                        </td> 
                                                        <td className="m-none">{asse.c2 ? asse.c2 : ''}</td> 
                                                        <td className="lp-plogo m-none">
                                                        {   asse.lp2 && asse.lp2.length > 0 && 
                                                            <img src={`https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/${asse.lp2.toUpperCase()}.jpg`}/>
                                                        } {asse.lp2 ? asse.lp2 : ''}
                                                        </td> 
                                                        {/* <td>{asse.l_s=='G' ? 'Gains' :asse.l_s=='H' ?'Hold' :'' }</td> */}
                                                    </tr>
                                                </>
                                            )})}
                            </table>
                        </div> 
                    </div> 
            </section>
        </div>
    </main>               
    <Footer />  
</>
)
}
export default ElectionResult