import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import FileSaver from "file-saver";
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { format, parseISO } from 'date-fns';


const Detail = () =>{
const history = useHistory();
const [show, setShow] = useState(false);
const [backImage, setBackImage] = useState('')
const {newsId} = useParams();
const [textToCopy, setTextToCopy] = useState('')
const [isLoading, setIsLoading] = useState(false);
const [collectionData, setCollectionData] = useState([]);
const [tgData, setTagsData] = useState([]);
const [login, setLogin] = useState(true)
const [errorMsg, setErrorMsg] = useState('');

const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
}
const [error, setError] = useState(null);

const imageDownload=(url)=>{

   setIsLoading(true);
   
            let modifiedUrl = url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          fetch('https://iansportalimages.s3.amazonaws.com/images/2024022952.jpg')
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
          handleSubmit(collectionData.title, collectionData.id)
         
  }

  const getData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/image/detail/${newsId}/?language=english`
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   let new_main_content_string = '';
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.results[0] && res.data.results[0].byline){
           let newIi = res.data.results[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }
   }
  
   setCollectionData({
   caption:res.data.results[0].caption,
   content: '<p>'+res.data.results[0].short_desc +'</p>'+ new_main_content_string,
   copy_content: res.data.results[0].content,
   id: res.data.results[0].id,
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   images_thumbnails: res.data.results[0].images_thumbnails,
   url: res.data.results[0].url,
   dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
 
 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.results[0] 
 && res.data.results[0].tags 
 && res.data.results[0].tags.length > 0 
 && res.data.results[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",collectionData)
setTagsData(tagData);
})
.catch(err => {
 console.log('Google api calendar error', err) 
 if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
   }else{
      setErrorMsg(err.response.data.detail)
   }
 }})
}
const handleSubmit = (title, id) => {
   //e.preventDefault();
   let formData = {
      "content_id":  id ? id : '',
      "username": localStorage && localStorage.userName ? localStorage.userName : '',
      "content_type": "Photo",
      "content_title": title ? title : ''
   }
   axios.post('https://cms.iansnews.in/api/download_records/', formData)
     .then(response => {
       console.log('Success:', response.data);
       // Optionally, reset form fields

     })
     .catch(error => {
       console.error('Error:', error);
     });
 };
 useEffect(() => {
   if(localStorage && localStorage.accessToken){
      setLogin(false)
   }else{
      setLogin(true)
   }
   if(!localStorage || localStorage.length == 0 || !localStorage.redirectTo){
      localStorage.setItem('redirectTo', newsId ? '/'+newsId :'');
      history.push('/login');
  }
   
   getData();
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content={collectionData.short_desc} />
            <meta property="og:title" content={collectionData.title} />
            <meta property="og:description" content={collectionData.short_desc} />
            <meta property="og:image" content={collectionData.image} />
            <meta property="og:url" content={'https://iansphoto.in/detail'+collectionData.slug} />
            <meta property="og:type" content="article" />
        </Helmet>
        
         <Header/>       
         <main id="main">
            <div className="container full-news full-pic-sec">
               <section id="about" className="about">
                     <div className="row">
                        <div className="col-lg-7">
                        <div id="print">                           
                           <div className="full-article">
                              <figure>
                              
                                 <a href={collectionData.img_slug} title={collectionData.title}>
                                    <img src={collectionData.image} alt={collectionData.title} />
                                 </a>
                                 <style>
                                    {`
                                       .full-article figure a:after {
                                          ${backImage};
                                       }
                                    `}
                                 </style>                            
                              </figure>
                           </div>
                        </div>
                        </div>
                        <div className="col-lg-5 latest-news-sec">
                           <div className="inews">
                              <h2 className="title" id="newsheading">{collectionData.title}</h2>
                           </div>
                           <p><strong>Photo ID:</strong> {collectionData.id}</p>
                           <p><strong>Caption:</strong> {collectionData.caption}</p>
                           <p><strong>Release Date & Time: </strong>{collectionData.dateTime}</p>
                           <p><strong>Tags: </strong> {tgData && tgData.length > 0 && tgData.map((tgg, t1)=>{
                                    return(
                                       <>
                                       <span key={t1}> {tgg.name} </span>
                                       
                                       </>
                                    )
                                 })},</p>
                           <div className="downloadhbtn">
                           <div className="download-img" onClick={()=>{
                                       imageDownload(collectionData.url)
                                    }}><i className="bi bi-download"> </i></div>
                           </div>
                        </div>

                     </div>
               </section>
            </div>
         </main>        
         <Footer/>          
        </>
    )
}

export default Detail