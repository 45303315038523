import React,{useState, useEffect,useRef, useCallback} from 'react'
import axios from 'axios'
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet"
import Latest from './Latest'
import HomeSlider from './HomeSlider'
import { format, parseISO } from 'date-fns'
import HeadingTab from './HeadingTab'
import VideoCat from './videoCat'
import { useHistory } from "react-router-dom"
import {useStickyBox} from "react-sticky-box";

const NewsReach = ()=>{
    const timeDiff = (timestamp)=>{
        const date = new Date(timestamp);
        const now = new Date();
        const timeDifference = now - date;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        let timeAgoString = '';

        if (hours > 0) {
          timeAgoString = `${hours} hour${hours > 1 ? 's' : ''}`;
        } else if (minutes > 0) {
          timeAgoString = `${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
          timeAgoString = `${seconds} second${seconds !== 1 ? 's' : ''}`;
        }
        return(`${timeAgoString} ago`);
    }

const [cateData, setCateData] = useState({})
const [inputSearch, setSearch] = useState()
const history = useHistory()
const inputRef = useRef(null)
const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
const [latData, setLatestData] = useState({})
const [loginModal, setLoginModal] = useState(false)
const {categoryId, offset} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [cateText, setCategoryName] = useState('all')

const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5);
const [requestFlag, setRequestFlag] = useState(true)
const onSubmit = (e) => {
    e.preventDefault()
    
    let path = ''
    if(inputSearch && inputSearch !='undefined'){
        path = '/english-search/'+inputSearch+'/1'
    }else{
        path = '/vmpl/1'
    }
        //SetSHead('template-search')
        //window.reload()
       // window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
    

}
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp);
    return format(date, 'MMMM dd, yyyy h:mm a');
}

const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/news/list/?language=hindi`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.map((item) => {
            // let tgSt= item.tags.filter(it => it.name === 'Business')
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/hindi-wire-detail/'+item.slug,
            // slugType: tgSt && tgSt.length > 0 ? true : false,
            image_caption: item.image_caption,
        }})
    setLatestData(latestStories)
    })
}
//alert(categoryId);
const fetchData = async (page) => {
const getApii = `https://cms.iansnews.in/api/newsreach/?language=english&page=${page}`;


   axios.get(getApii)
     .then(res => {

if(lastPage == 1){
    setLastPage(res.data.numpages);
}
const getCatData =
res.data.results &&
res.data.results.length > 0 &&
res.data.results.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.thumbnail,
    dateTime: item.updated_at ? formatDate(item.updated_at): '',
    dateTimDff: item.created_at ? timeDiff(item.created_at):'',
    title: item.title,
    tags: item.tags,
    slug:'/vmpl-detail/'+item.slug,
    video_preview:item.video_url? item.video_url :'',
    image_caption: item.image_caption,
};
});
        setCateData(getCatData);
        setLt(res.data.numpages);

     setRequestFlag(false);
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })

}
const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/vmpl/'+startPg
    

    history.push(pathh)
    
}
const [isVisible, setIsVisible] = useState(false)
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
useEffect(() => {

  if(localStorage && localStorage.accessToken){
    setLoginModal(true)
  }
  setCurrentPage(offset)
  setStartPage(offset)
 // fetchData(currentPage)
  LatestData()
  if(currentPage == offset){
    fetchData(currentPage)
    setStartPage(currentPage)

}
scrollToTop()

window.addEventListener("scroll", toggleVisibility)
return () => window.removeEventListener("scroll", toggleVisibility)
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId, offset])

const handleNextPage = () => {
if(lastPage > currentPage){
  setCurrentPage(parseInt(currentPage) + 1);
  let newArry = pageList;
  let newAddArray = pageList[pageList.length-1]+1;
  // if(currentPage > 2){
    let newRemoveArray = pageList[0];
  //
  // }
  newArry.shift(newRemoveArray);
  newArry.push(newAddArray);
    setPageList(newArry);

   }

}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
const handlePrevPage = () => {
if(currentPage > 1){

  setCurrentPage(currentPage - 1);
  // setCurrentPage(currentPage + 1);
  let newArry = pageList;

  let newAddArray = pageList[pageList.length-1];
  let newRemoveArray = pageList[0]-1;
    newArry.unshift(newRemoveArray);
  newArry.pop(newAddArray);
setPageList(newArry);
}
}


    return(
        <>
        <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Indo-Asian News Service</title>
        <meta data-meta-title="IANS IN" name="keywords" content={''} />
        <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
        <Header />
        <HomeSlider />
         <main id="main">
            <div className="container video-newsec">
                <section className="about">
                <HeadingTab />
                <div className="row">
                    <div className="col-lg-9 order2" id="content1">
                    <ul className="top-category-list-bottom news-wire-list">
                        <div className="main-heading">
                        <h2 className="heading">{"NewsReach"}</h2>
                        </div>

                        { cateData && cateData.length > 0 && cateData.map((engTemp, et)=>{
                        return(
                            <>
                                <div className="container-fluid sb-4" key={et}>
                                    <div className="row g-0">
                                        <li>
                                            <figure>
                                                <figcaption className="tabtextarea-top-category-list-bottom">
                                                <NavLink to={engTemp.slug} title={engTemp.title}><h3 className="title">{engTemp.title}</h3></NavLink>
                                                    <div className="cat-date-wrap-1">
                                                    {engTemp.tags && engTemp.tags.length > 0 && engTemp.tags.map((tgg,t)=>{
                                                        return(
                                                        <span className="cd-cat-M" key={t}><NavLink to={`/vmpl/${tgg.slug}/1`}>{tgg.name}</NavLink></span>
                                                    )})}
                                                    <span className="cd-date-M">{engTemp.dateTime}</span>
                                                    </div>
                                                    <p>{engTemp.content}</p>
                                                </figcaption>
                                            </figure>
                                        </li>
                                    </div>
                                </div>
                            </>
                    )})}

                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                            { currentPage && currentPage > 1 &&                              
                                <li className="page-item" onClick={handlePrevPage}>
                            <NavLink to={`/vmpl/${currentPage-1}`} className="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                            </NavLink>
                                </li>
                            }
                                <li className="page-item" onClick={()=>{
                                    setCurrentPage(currentPage)
                                    }}>
                            <a className="page-link active" href="#">{currentPage}</a></li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(parseInt(currentPage)+1)
                                }}>
                                <NavLink to={`/vmpl/${parseInt(currentPage)+1}`} className="page-link">{parseInt(currentPage)+1}</NavLink>
                                </li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(parseInt(currentPage)+2)
                                }}>
                                <NavLink to={`/vmpl/${parseInt(currentPage)+2}`} className="page-link">{parseInt(currentPage)+2}</NavLink>
                                </li>
                                <li className="page-item" onClick={handleNextPage}>
                                <NavLink to={`/vmpl/${parseInt(currentPage) + 1}`} className="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </NavLink>
                                </li>
                                <li className="go_page"> Page 
                                    <input type="number" name="quantity" min={startPg} max={lastPage}  onChange={inputGSData} value={startPg}/>
                                    <i className="go_btn" aria-hidden="true" onClick={GoToPage}>GO</i>
                                </li>
                            </ul>
                        </nav>
                    </ul>
                </div>
               
            
                    <Latest data={latData} slug={'/vmpl/'} ref={stickyRef}/>
                
            
                </div>
                        
                </section>
            </div>
         </main>               
    <Footer />  
        </>
    )
}
export default NewsReach